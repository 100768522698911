import { Typography, Container, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { SUSTAINABLE_LINEHAUL } from '../../../data/analytics';

import linehaulHeader from '../../../images/OurInnovation/linehaulHeader.webp';
import linehaulBackground from '../../../images/OurInnovation/linehaulBackground.png';
import linehaulEngine from '../../../images/OurInnovation/linehaulEngine.png';
import linehaulVan from '../../../images/OurInnovation/linehaulVan.png';
import linehaulVanSmall from '../../../images/OurInnovation/linehaulVanSmall.png';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import theme from '../../../theme';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';
import usePageHalfWidth from '../../../utils/usePageHalfWidth';

const SustainableLinehaul = () => {
  const hvoSavingsCards = [
    {
      preText: 'Since December 2023',
      title: 'We have deployed HVO across a proportion of our HGV fleet',
      subtitle: 'which reduced our CO2e by over',
      title2: '78,000 tonnes',
      subtitle2: 'in 2023',
    },
  ];

  const hvoDetailsCards = [
    {
      title: 'Reductions of up to 90% ',
      subtitle: 'in carbon dioxide emissions compared to standard diesel.',
    },
    {
      title: 'Approved by all major manufacturers',
      subtitle: 'for 100% use in generators and commercial vehicles.',
    },
    {
      title: '85% reduction in particulate matter',
      subtitle:
        'and a 30% reduction in nitrous oxide emissions compared to standard diesel. ',
    },
    {
      title: 'Beneficial compared to other biofuels',
      subtitle:
        "because it is produced from waste meaning and therefore doesn't require the use of agricultural land which could be used for food production.",
    },
  ];

  return (
    <Trackable
      interfaceId={SUSTAINABLE_LINEHAUL.INTERFACE_ID}
      loadId={SUSTAINABLE_LINEHAUL.ON_LOAD}
    >
      <PageHeader image={linehaulHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Our Sustainable Innovation
        </Typography>
        <Typography variant="h1" color="#FFF">
          Sustainable Fleet
        </Typography>
      </PageHeader>
      <SideBySide
        image={linehaulEngine}
        imageSide="right"
        imageBackground={linehaulBackground}
        verticalPadding={30}
        mobileDirection="column-reverse"
        mobileVerticalPadding={6}
      >
        <Typography
          variant="h4Light"
          gutterBottom
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && {
              paddingTop: 5,
              textAlign: 'center',
            }
          }
        >
          Our fleet of HGVs (heavy goods vehicles) transport our customers'
          parcels from our Hubs in the Midlands to depots across Great Britain
          from where they are then delivered.
        </Typography>
        <Typography
          variant="body1"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && {
              textAlign: 'center',
            }
          }
        >
          These vehicles represent a larger portion of our total CO2e, we are
          committed to diversifying our linehaul fleet to include alternative
          options such as biogas, biofuel, electric and hydrogen powered trucks.
        </Typography>
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 12,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h2Light"
            paddingBottom={2}
            color={theme.palette.primary.core}
            textAlign="center"
          >
            Hydrogenated Vegetable Oil (HVO)
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              pb: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hvoSavingsCards.map(card => (
              <Grid item sm={6} key={card.title} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.core,
                    borderRadius: 1,
                    height: '100%',
                    py: '55px',
                    px: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="h6" color="#FFF" textAlign="center">
                      {card.preText}
                    </Typography>
                    <Typography
                      variant="h3Light"
                      color="#FFF"
                      textAlign="center"
                    >
                      {card.title}
                    </Typography>
                    {card.subtitle && (
                      <Typography variant="h6" color="#FFF" textAlign="center">
                        {card.subtitle}
                      </Typography>
                    )}
                    {card.title2 && (
                      <Typography
                        variant="h3Light"
                        color="#FFF"
                        textAlign="center"
                      >
                        {card.title2}
                      </Typography>
                    )}
                    {card.subtitle2 && (
                      <Typography variant="h6" color="#FFF" textAlign="center">
                        {card.subtitle2}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography
            variant="h4Light"
            paddingBottom={2}
            color="#000"
            textAlign="center"
          >
            HVO is a diesel replacement which is made entirely from recycled
            fryer oil.
          </Typography>
          <Typography
            variant="body1"
            paddingBottom={2}
            color="#000"
            textAlign="center"
          >
            HVO is a 100% drop-in replacement for diesel, meaning that it can be
            used in a standard diesel engine with no mechanical modifications.
          </Typography>
          <Grid container spacing={2}>
            {hvoDetailsCards.map(card => (
              <Grid item md={3} key={card.title} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.core,
                    borderRadius: 1,
                    height: '100%',
                    py: 5.5,
                    px: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Typography
                      variant="h5Light"
                      paddingBottom={2}
                      color="#FFF"
                      textAlign="center"
                    >
                      {card.title}
                    </Typography>
                    <Typography variant="h6" color="#FFF" textAlign="center">
                      {card.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          pt: useMediaQuery(theme.breakpoints.down('md')) && 6,
        }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: useMediaQuery(theme.breakpoints.down('md'))
                ? 'left'
                : 'right',
            }}
          >
            <Box
              sx={{
                pl: usePadding(),
                width: usePageHalfWidth(),
                pr: useMediaQuery(theme.breakpoints.down('md')) && 4,
              }}
            >
              <Typography
                variant="h1Light"
                gutterBottom
                color={theme.palette.primary.core}
              >
                Battery Electric
              </Typography>
              <Typography
                variant="h5Light"
                gutterBottom
                color="#000"
                sx={
                  useMediaQuery(theme.breakpoints.down('md')) && {
                    paddingBottom: 3,
                  }
                }
              >
                We are exploring the development of the infrastructure to
                support battery electric HGVs and are trialling electric HGVs.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={
              useMediaQuery(theme.breakpoints.down('md')) && {
                display: 'flex',
                justifyContent: 'center',
              }
            }
          >
            <Box
              sx={{ float: 'right', maxWidth: '100%' }}
              component="img"
              src={
                useMediaQuery(theme.breakpoints.down('md'))
                  ? linehaulVanSmall
                  : linehaulVan
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Trackable>
  );
};

export default SustainableLinehaul;
