import theme from '../theme';

import roadmap1 from '../images/RoadMap/roadmap1.png';
import roadmap2 from '../images/RoadMap/roadmap2.png';
import roadmap3 from '../images/RoadMap/roadmap3.png';
import roadmap4 from '../images/RoadMap/roadmap4.png';
import roadmap5 from '../images/RoadMap/roadmap5.png';
import roadmap6 from '../images/RoadMap/roadmap6.png';
import roadmap7 from '../images/RoadMap/roadmap7.png';
import roadmap15 from '../images/RoadMap/roadmap15.png';

export const ROADMAP = [
  {
    id: 1,
    date: '2009',
    text:
      'DPD UK achieved ISO14001 environmental accreditation demonstrating our green credentials',
    background: theme.palette.primary.background,
  },
  {
    id: 2,
    image: roadmap1,
  },
  {
    id: 3,
    date: '2013',
    text: 'Offsetting ALL our delivery emissions, free of charge to customers',
    background: theme.palette.primary.background,
  },
  {
    id: 4,
    date: '2017',
    text: 'First electric vehicles (EVs) purchased',
    background: theme.palette.primary.background,
  },
  {
    id: 5,
    image: roadmap2,
  },
  {
    id: 6,
    text: 'Renewable electricity in all sites',
    align: 'center',
    background: theme.palette.primary.core,
    textVariant: 'h5Light',
  },
  {
    id: 7,
    date: '2018',
    text: 'Opening of our first all-electric micro depot - Westminster',
    background: theme.palette.primary.background,
  },
  {
    id: 8,
    image: roadmap3,
  },
  {
    id: 9,
    date: '2019',
    text:
      '100% recyclable Expresspak introduced made from 80% reclaimed material from the agricultural industry',
    background: theme.palette.primary.background,
  },
  {
    id: 10,
    image: roadmap4,
    background: '#F6FBF6',
  },
  {
    id: 11,
    date: '2020',
    text:
      "Launched our fourth strategic pillar to become the UK's most sustainable delivery company",
    background: theme.palette.primary.background,
  },
  {
    id: 12,
    image: roadmap5,
  },
  {
    id: 13,
    text: 'Launched the DPD Eco Fund supporting UK-wide green projects',
    background: theme.palette.primary.background,
  },
  {
    id: 14,
    text: '10% of delivery fleet electric in all depots',
    textVariant: 'h4Light',
    background: theme.palette.primary.core,
  },
  {
    id: 15,
    image: roadmap15,
    background: theme.palette.primary.background,
  },
  {
    id: 16,
    date: '2021',
    text:
      'Announced Vision 25-25-25 for 25 cities to have all-electric delivery by 2025',
    background: theme.palette.primary.background,
  },
  {
    id: 17,
    text: 'First fully green DPD delivery city goes live: Oxford',
    background: theme.palette.primary.background,
  },
  {
    id: 18,
    image: roadmap6,
  },
  {
    id: 19,
    text: '7,000 solar panels installed at our Hinckley Hub 5',
    textVariant: 'h4Light',
    background: theme.palette.primary.core,
  },
  {
    id: 20,
    image: roadmap7,
  },
  {
    id: 21,
    text: '1,000 EVs purchased',
    textVariant: 'h4Light',
    background: theme.palette.primary.core,
  },
  {
    id: 22,
    text: '0% waste to landfill and 90% of waste is reused or recycled',
    background: theme.palette.primary.background,
  },
  {
    id: 23,
    date: '2022',
    text:
      'Vision 25-25-25 expands to Vision 30 with an additional five towns and cities',
    background: theme.palette.primary.background,
  },
  {
    id: 24,
    text: '2,000 EVs purchased',
    textVariant: 'h4Light',
    background: theme.palette.primary.core,
  },
  {
    id: 25,
    text: '10 compressed natural gas HGVs arrive on fleet',
    background: theme.palette.primary.background,
  },
  {
    id: 26,
    date: '2023',
    text: 'All new company cars will be all-electric',
    background: theme.palette.primary.background,
  },
  {
    id: 27,
    text: 'Hydrogenated Vegetable Oil deployed to 95% of our HGV fleet',
    background: theme.palette.primary.background,
  },
  {
    id: 28,
    text: 'Our Future Road Map',
    textVariant: 'h4Light',
    align: 'center',
    background: theme.palette.primary.core,
  },
  {
    id: 29,
    date: '2030',
    text:
      'DPD as a part of GEOPOST will achieve its Science Based Target initiative (SBTi) near-term target of a 43% reduction in total emissions from a base year of 2020',
    background: theme.palette.primary.background,
    sizeLg: 6,
  },
  {
    id: 30,
    date: '2040',
    text:
      'DPD as a part of GEOPOST will achieve its Science Based Target initiative (SBTi) net zero target set against a base year of 2020',
    background: theme.palette.primary.core,
    sizeLg: 6,
  },
];
