import { Container, Typography, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { FARESHARE } from '../../../data/analytics';

import fareshareHeader from '../../../images/Social/fareshareHeader.webp';
import fareshareBackground from '../../../images/Social/fareshareBackground.png';
import fareshareGousto from '../../../images/Social/fareshareGousto.png';
import fareshareHelloFresh from '../../../images/Social/fareshareHelloFresh.png';
import fareshareMindfulChef from '../../../images/Social/fareshareMindfulChef.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const Fareshare = () => {
  return (
    <Trackable interfaceId={FARESHARE.INTERFACE_ID} loadId={FARESHARE.ON_LOAD}>
      <PageHeader image={fareshareHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Social
        </Typography>
        <Typography variant="h1" color="#FFF">
          FareShare Food Donations
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 20,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h3Light'
                : 'h2Light'
            }
            color={theme.palette.primary.core}
            gutterBottom
            textAlign="center"
          >
            Since August 2021, in partnership with:
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid
              item
              md={4}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box component="img" src={fareshareHelloFresh} />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box component="img" src={fareshareGousto} />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box component="img" src={fareshareMindfulChef} />
            </Grid>
          </Grid>
          <Typography variant="h4Light" color="#000" textAlign="center">
            During 2023 DPD donated the equivalent of{' '}
            <Typography variant="h2Light" color={theme.palette.primary.core}>
              72,977{' '}
            </Typography>
            meals*, far surpassing our estimated impact. We are proud to support
            the work of FareShare in partnership with our providers.
            <br />
            <Typography variant="annotation" gutterBottom color="#000">
              *The equivalent meals calculation is based on WRAP’s guidance
              around reporting the amounts of surplus food redistributed.
            </Typography>
          </Typography>
        </Box>
      </Container>
      <PageHeader image={fareshareBackground} />
    </Trackable>
  );
};

export default Fareshare;
