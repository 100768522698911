import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';

const VanStatsTable = ({ stats }) => {
  return (
    <TableContainer
      sx={{
        borderStyle: 'solid',
        borderColor: theme.palette.primary.core,
        borderWidth: 1,
        borderBottomWidth: 0,
        borderRadius: 1,
      }}
    >
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderColor: theme.palette.primary.core,
            borderWidth: 0,
            borderBottomWidth: 1,
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentCore}
              >
                Payload
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" color={theme.palette.primary.core}>
                {stats.payload}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#FFF' }}>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentCore}
              >
                Parcel Capacity
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" color={theme.palette.primary.core}>
                {stats.capacity}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentCore}
              >
                Maximum Range
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" color={theme.palette.primary.core}>
                {stats.range}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#FFF' }}>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentCore}
              >
                Battery Size
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" color={theme.palette.primary.core}>
                {stats.battery}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VanStatsTable;

VanStatsTable.propTypes = {
  stats: PropTypes.object,
};
