import { Grid, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import theme from '../../theme';

const NavBarLink = ({ nav, variant }) => {
  const { pathname } = useLocation();

  return (
    <Grid item key={nav.link}>
      <NavLink to={nav.link} style={{ textDecoration: 'none' }}>
        <Typography
          variant={variant}
          sx={
            pathname === nav.link || `/${pathname.split('/')[1]}` === nav.link
              ? {
                  color: theme.palette.primary.core,
                  textDecoration: 'underline',
                  textDecorationColor: theme.palette.primary.core,
                  textUnderlineOffset: 4,
                }
              : {
                  color: theme.palette.primary.main,
                  '&:hover': {
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.main,
                    textUnderlineOffset: 4,
                  },
                }
          }
        >
          {nav.name}
        </Typography>
      </NavLink>
    </Grid>
  );
};

export default NavBarLink;

NavBarLink.propTypes = {
  nav: PropTypes.object.isRequired,
  variant: PropTypes.string,
};
