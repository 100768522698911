import {
  Typography,
  Container,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';

import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { ECO_FUND } from '../../../data/analytics';

import ecoHeader from '../../../images/Social/ecoHeader.webp';
import ecoColliersWoodBackground from '../../../images/Social/ecoColliersWoodBackground.png';
import ecoColliersWood from '../../../images/Social/ecoColliersWood.png';
import ecoWareham from '../../../images/Social/ecoWareham.png';
import ecoSeaGrass from '../../../images/Social/ecoSeaGrass.png';
import rspbWetlands from '../../../images/Social/rspbWetlands.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';

import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import { ECO_FUND_FORESTRY } from '../../../data/links';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

import treeAppLogo from '../../../images/Social/treeApp-logo.png';
import treeAppBrazil from '../../../images/Social/treeApp-Brazil.jpg';
import treeAppBurundi2 from '../../../images/Social/treeApp-Burundi2.jpg';
import treeAppIndonesia2 from '../../../images/Social/treeApp-Indonesia2.jpg';

import ActionButton from '../../../components/actionButton/ActionButton.jsx';
import socialTreeappImpactReport2024 from '../../../images/Social/treeappImpactReport2024.png';
import { treeappImpactReport2024 } from '../../../data';

const EcoFund = () => {
  return (
    <Trackable interfaceId={ECO_FUND.INTERFACE_ID} loadId={ECO_FUND.ON_LOAD}>
      <PageHeader image={ecoHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Social
        </Typography>
        <Typography variant="h1" color="#FFF">
          DPD Eco Fund
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography variant="h4Light" color="#000" gutterBottom>
            The Eco Fund was established to utilise the revenue from recycling
            specific waste streams generated by our operations to support
            business and charitable endeavours in the fight against climate
            change. Through this fund we have supported both short and long term
            projects, the recipients being selected through submission to our
            Eco Fund Committee on a quarterly basis.
          </Typography>
        </Box>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
          pt: 10,
        }}
      >
        <Card
          raised={false}
          elevation={0}
          sx={{
            display: 'flex',
            width: usePageWidth(),
            flexDirection: useMediaQuery(theme.breakpoints.down('md'))
              ? 'column-reverse'
              : 'row',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.primary.background,
            }}
          >
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                variant="h2Light"
                color={theme.palette.primary.core}
                gutterBottom
              >
                TreeApp
              </Typography>
              <Typography variant="h5Light" color="#000">
                Go Zero Be a Hero - We have been partnered with Treeapp for the
                last two years, planting a tree for every 10 deliveries a
                customer receives in an electric vehicle. Most recently our
                trees have supported projects in Brazil, Burundi, and Indonesia.
                Treeapp is a certified B corp, meaning it has been verified as
                meeting high standards of social and environmental performance,
                transparency, and accountability.
              </Typography>
            </CardContent>
          </Box>
          <CardMedia
            component="img"
            sx={{
              objectFit: 'contain',
              width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 325,
            }}
            image={treeAppLogo}
            alt="TreeApp logo"
          />
        </Card>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h4Light"
            color="#000"
            paddingBottom={2}
            paddingTop={2}
          >
            Our sponsored projects to date
          </Typography>
        </Box>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          py: 2,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
          // px: usePadding(),
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: useMediaQuery(theme.breakpoints.down('md'))
              ? 'column'
              : 'row',
            borderRadius: '10px',
            width: usePageWidth(),
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 200,
            }}
            image={treeAppBrazil}
            alt="Brazil"
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                variant="h5"
                gutterBottom
                color={theme.palette.primary.core}
              >
                Brazil
              </Typography>
              <Typography variant="h5Light">
                Local teams have planted trees in areas that require it the
                most. This has contributed to enhancing the connectivity of
                biodiversity corridors for primate conservation and utilising
                local labour, representing shared benefits to local communities
                and preservation of crucial areas.
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          py: 2,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: useMediaQuery(theme.breakpoints.down('md'))
              ? 'column-reverse'
              : 'row',
            borderRadius: '10px',
            width: usePageWidth(),
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
                color={theme.palette.primary.core}
              >
                Indonesia
              </Typography>
              <Typography variant="h5Light" display="flex" sx={{ pb: 4 }}>
                Mangroves have been successfully planted along the western
                shores of the island of Lombok with the support of local
                communities.
              </Typography>
              <Typography variant="h6" display="wrap">
                Nearly 93% of these trees reached maturity within 2 years after
                the planting season and the site is being used for educating
                local primary school children on the importance of trees.{' '}
              </Typography>
            </CardContent>
          </Box>
          <CardMedia
            component="img"
            sx={{
              width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 200,
            }}
            image={treeAppIndonesia2}
            alt="Indonesia"
          />
        </Card>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          py: 2,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
          pb: 10,
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: useMediaQuery(theme.breakpoints.down('md'))
              ? 'column'
              : 'row',
            borderRadius: '10px',
            width: usePageWidth(),
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 200,
            }}
            image={treeAppBurundi2}
            alt="Burundi"
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                variant="h5"
                gutterBottom
                color={theme.palette.primary.core}
              >
                Burundi
              </Typography>
              <Typography variant="h5Light">
                While planting has yet to commence, local teams are focused on
                tending to the tree nursery and preparing for the upcoming
                planting season.
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Container>
      <SideBySide
        image={socialTreeappImpactReport2024}
        imageSide="right"
        background={theme.palette.primary.background}
        verticalPadding={12}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Treeapp Impact Report 2024
        </Typography>
        <Typography variant="h4Light" gutterBottom>
          Download a copy of our 2024 Treeapp Impact Report below
        </Typography>
        <ActionButton
          text="Treeapp Impact Report"
          onclick={() => {
            window.open(treeappImpactReport2024, '_blank');
          }}
        />
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            RSPB wetlands project
          </Typography>
          <Typography variant="h4Light" color="#000" gutterBottom>
            We have been working with the RSPB to restore a sand and gravel
            quarry on the banks of the River Trent in Nottinghamshire. Failing
            sluices had hindered the management of water levels at this site, an
            essential process to keep the reedbed healthy and attractive to the
            range of wildlife that use this threatened habitat. We provided
            funding to install two new, double-leaf penstock sluices to aid the
            on-going establishment of the reedbed and increase the biodiversity
            of the site.
          </Typography>

          <Box component="img" src={rspbWetlands} sx={{ maxWidth: '100%' }} />
          <Typography variant="body1">A new sluice funded by DPD</Typography>
        </Box>
      </Container>
      <BoxOnBackground
        background={ecoSeaGrass}
        boxSide="left"
        verticalPadding={25}
        mobileBottomPadding={48}
      >
        <Typography variant="h2Light" color="#FFF" gutterBottom>
          WWF Seagrass
        </Typography>
        <Typography variant="h4Light" color="#FFF" gutterBottom>
          We have supported the WWF to fund a project which saw us donate
          £40,000 to plant 1,600m² of seagrass meadow in the Solent.
        </Typography>
        <Typography variant="body1" color="#FFF" gutterBottom>
          Seagrass absorbs carbon 35 times faster than a tropical rainforest,
          making it a key weapon in the fight against climate change. Data from
          the US suggests seagrass meadows can absorb 420kg of carbon equivalent
          per 10,000m² per year.* Therefore, 1,600m² of seagrass meadow could
          absorb 67.2kg of carbon equivalent per year.
        </Typography>
        <Typography variant="h6" color="#FFF">
          *this is a net number that considers the flux of all GHG.
        </Typography>
      </BoxOnBackground>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            Forestry England
          </Typography>
          <Typography variant="h4Light" color="#000" gutterBottom>
            Our four year partnership with Forestry England is supported by our
            Eco Fund, and we aim to plant thousands of trees at five different
            sites spread across England.
          </Typography>
          <iframe
            title={ECO_FUND_FORESTRY}
            src={ECO_FUND_FORESTRY}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={
              useMediaQuery(theme.breakpoints.down('md')) ? '200px' : '550px'
            }
            width="100%"
          />
        </Box>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          backgroundImage: `url(${ecoColliersWoodBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 6 : 20,
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography variant="h2Light" color="#FFF" gutterBottom>
            Colliers Wood
          </Typography>
          <Typography variant="h4Light" color="#FFF" gutterBottom>
            In March 2022 the partnership was officially launched at Colliers
            Wood. Colliers Wood is an urban woodland covering over 136 hectares
            of a former coal mining site. More than 4,000 new trees are being
            planted in 2022 to increase the biodiversity of the woodland while
            expanding the existing tree cover.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
            <Box
              component="img"
              src={ecoColliersWood}
              sx={{ maxWidth: '100%' }}
            />
          </Box>
          <Typography variant="body1" color="#FFF" paddingBottom={2}>
            Local children from St Gabriel's Catholic Primary School joined
            Forestry England and DPD representatives to plant the first
            saplings, and the rest of the new trees will be planted during the
            winter of 2022.
          </Typography>
          <Typography variant="body1" color="#FFF">
            Other sites across England will benefit from DPD's support,
            including Pleasant Forest in Kent, a new 127-hectare woodland which
            Forestry England is creating. At Dimmingsdale in Staffordshire,
            restoration work will be carried out to replace trees affected by
            disease and in Hamsterley Forest, County Durham, a new woodland for
            wellbeing will be created, with 100 ornamental oak trees, a timber
            shelter and seating to provide a new accessible space for visitors.
          </Typography>
        </Box>
      </Container>
      <SideBySide
        image={ecoWareham}
        imageSide="right"
        background={theme.palette.secondary.backgroundWebApp}
        verticalPadding={25}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Wareham
        </Typography>
        <Typography variant="h4Light" color="#000" gutterBottom>
          Additionally, DPD has supported Forestry England to replant 79,000
          trees in Wareham Forest between 2020 and 2022. The trees cover the
          area of over 40 football pitches and were financed through our Eco
          Fund.
        </Typography>
        <Typography variant="body1" color="#000" gutterBottom>
          In May 2020 large areas of Wareham Forest were destroyed by a
          ferocious forest fire. The fire burnt for over two weeks from the 18th
          of May destroying an area the size of 350 football pitches.
        </Typography>
        <Typography variant="body1" color="#000" gutterBottom>
          It will take several decades to fully recover from the damage and was
          home to rare bare-ground invertebrates as well as ground-nesting birds
          such as the woodlark and native reptiles, in particular the endangered
          sand lizards and smooth snakes.
        </Typography>
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
        background={theme.palette.secondary.backgroundWebApp}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            Upcoming projects
          </Typography>
          <Typography variant="h5" color="#000" gutterBottom>
            Dimmingsdale tree planting in partnership with Forestry England
          </Typography>
          <Typography variant="h5Light" color="#000" gutterBottom>
            We will be embarking on a new project site at Dimmingsdale, planting
            4000 trees between Autumn 2023 and Spring 2024
          </Typography>
        </Box>
      </Container>
    </Trackable>
  );
};

export default EcoFund;
