import { Typography, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import defaultCard from '../../images/Home/homeBackground.webp';
import theme from '../../theme';

const ArticleCard = ({
  article,
  readMoreId,
  imgWidth = '100%',
  imgHeight = 200,
}) => {
  const navigate = useNavigate();
  const tracker = useTracker();

  const goToArticle = () => {
    navigate(`/news/${article.id}`);
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        borderRadius: 1,
        backgroundColor: '#FDFFFB',
      }}
    >
      <Box
        component="img"
        src={article.image || defaultCard}
        sx={{
          width: imgWidth,
          height: imgHeight,
          borderRadius: 1,
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      <Box sx={{ p: 2 }}>
        <Typography
          variant="body1"
          color={theme.palette.primary.accentCore}
          paddingBottom={2}
        >
          {article.date}
        </Typography>
        <Box
          sx={{
            minHeight: '48px',
            alignItems: 'flex-end',
            display: 'flex',
            mb: 2,
          }}
        >
          <Typography variant="h5Light" color={theme.palette.primary.core}>
            {article.cardTitle ? article.cardTitle : article.title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="#000"
          marginBottom={2}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box !important',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal',
          }}
        >
          {article.subtitle}
        </Typography>
        {readMoreId !== undefined && (
          <Button
            onClick={() => {
              goToArticle();
              tracker.logEvent(readMoreId);
            }}
            fullWidth
            sx={{
              color: '#FFF',
              backgroundColor: theme.palette.primary.core,
              borderRadius: 1,
              py: '15px',
              '&:hover': {
                backgroundColor: theme.palette.primary.core,
                opacity: 0.8,
              },
            }}
          >
            <Typography variant="button">Read More</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ArticleCard;

ArticleCard.propTypes = {
  article: PropTypes.object,
  readMoreId: PropTypes.string,
  imgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
