import { useState } from 'react';
import { Typography, Container, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable, useTracker } from '@dpdgroupuk/react-event-tracker';
import { DOCUMENTS } from '../../data/analytics';

import documentsISO from '../../images/Documents/documentsISO.png';
import documentsHeader from '../../images/Documents/documentsHeader.webp';
import documentsPolicy2024 from '../../images/Documents/documentsPolicy2024.png';
import documentsGlossary from '../../images/Documents/documentsGlossary.png';
import documentsEcovadis from '../../images/Documents/documentsEcovadisMedal.png';
import documentsReport2023 from '../../images/Documents/documentsReport2023.png';
import {
  ISO14001,
  // reducingCO2,
  // reducingWaste,
  sustainabilityPolicy2024,
  sustainabilityReport2023,
  sustainabilityGlossary,
  netZeroApproval,
  SBTiCertificate,
} from '../../data';

import theme from '../../theme';
import PageHeader from '../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../components/sideBySide/SideBySide.jsx';
import ActionButton from '../../components/actionButton/ActionButton.jsx';
import SpeakerDialog from '../../components/speakerDialog/SpeakerDialog.jsx';
import {
  DOCUMENTS_FRANCIS_POPE,
  DOCUMENTS_OLIVER_LORD,
  DOCUMENTS_OLLY_CRAUGHAN,
  DOCUMENTS_TOM_BYRNE,
  DOCUMENTS_ANDY_STREET,
  DOCUMENTS_ROSAMUND_ADOO,
  DOCUMENTS_GERAINT_DAVIES,
} from '../../data/links';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';

const Documents = () => {
  const tracker = useTracker();
  const largeBreatheSpeakers = [
    {
      name: 'Dr Francis Pope',
      title: 'Professor of Atmospheric Science, University of Birmingham',
      link: DOCUMENTS_FRANCIS_POPE,
    },
    {
      name: 'Oliver Lord',
      title: 'Head of Policy and Campaigns, Environmental Defence Fund',
      link: DOCUMENTS_OLIVER_LORD,
    },
    {
      name: 'Tom Bryne',
      title: 'Head of Sustainability, ASOS',
      link: DOCUMENTS_TOM_BYRNE,
    },
    {
      name: 'Olly Craughan',
      title: 'Head of CSR, DPD',
      link: DOCUMENTS_OLLY_CRAUGHAN,
    },
  ];

  const smallBreatheSpeakers = [
    {
      name: 'Geraint Davies',
      title: 'MP for Swansea West',
      link: DOCUMENTS_GERAINT_DAVIES,
    },
    {
      name: 'Andy Street',
      title: 'Mayor of the West Midlands',
      link: DOCUMENTS_ANDY_STREET,
    },
    {
      name: 'Rosamund Adoo-Kissi-Debrah',
      title: 'WHO Advocate and founder of the Ella Roberta Family Foundation',
      link: DOCUMENTS_ROSAMUND_ADOO,
    },
  ];

  const [selectedSpeaker, setSelectedSpeaker] = useState('');

  return (
    <Trackable interfaceId={DOCUMENTS.INTERFACE_ID} loadId={DOCUMENTS.ON_LOAD}>
      <PageHeader image={documentsHeader}>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Documents & Videos
        </Typography>
        <Typography variant="h4" color="#FFF" gutterBottom>
          View our Thought Leadership papers, Breathe Easy videos and
          certificates
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.secondary.background,
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            SBTi Net Zero Targets
          </Typography>
          <Typography variant="h4Light" gutterBottom>
            DPD UK as a part of the wider Geopost group holds SBTi validated Net
            Zero targets for Near-Term and Long-Term carbon emissions reduction
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            {' '}
            <ActionButton
              text="SBTi Near Term Certificate"
              onclick={() => {
                window.open(SBTiCertificate, '_blank');
              }}
            />
          </Box>
          <Box sx={{ marginTop: 2 }}>
            {' '}
            <ActionButton
              text="SBTi Validation Letter"
              onclick={() => {
                window.open(netZeroApproval, '_blank');
              }}
            />
          </Box>
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.primary.background,
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            EcoVadis
          </Typography>
          <Typography variant="h4Light" gutterBottom>
            Geopost is happy to hold a silver EcoVadis rating:
          </Typography>
          <Typography variant="h4Light" gutterBottom>
            As a subsidiary of Geopost, DPD UK is included the submission to
            EcoVadis:
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            {' '}
            <ActionButton
              text="EcoVadis Medal"
              onclick={() => {
                window.open(documentsEcovadis, '_blank');
              }}
            />
          </Box>
        </Box>
      </Container>
      <SideBySide
        image={documentsISO}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={6}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          ISO 14001
        </Typography>
        <Typography variant="h4Light" gutterBottom>
          DPD are proud to hold ISO14001 environmental management systems
          accreditation;
        </Typography>
        <ActionButton
          text="Download Accreditation"
          onclick={() => {
            window.open(ISO14001, '_blank');
            tracker.logEvent(DOCUMENTS.CLICK_ISO);
          }}
        />
      </SideBySide>
      <SideBySide
        image={documentsReport2023}
        imageSide="right"
        background={theme.palette.primary.background}
        verticalPadding={12}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Sustainability Report
        </Typography>
        <Typography variant="h4Light" gutterBottom>
          Download a copy of our 2023 Sustainability Report below
        </Typography>
        <ActionButton
          text="Sustainability Report"
          onclick={() => {
            window.open(sustainabilityReport2023, '_blank');
            tracker.logEvent(DOCUMENTS.CLICK_SUSTAINABILITY_POLICY);
          }}
        />
      </SideBySide>
      <SideBySide
        image={documentsPolicy2024}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={12}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Sustainability Policy
        </Typography>
        <Typography variant="h4Light" gutterBottom>
          Download a copy of our 2024 Sustainability Policy below
        </Typography>
        <ActionButton
          text="Sustainability Policy"
          onclick={() => {
            window.open(sustainabilityPolicy2024, '_blank');
            tracker.logEvent(DOCUMENTS.CLICK_SUSTAINABILITY_REPORT);
          }}
        />
      </SideBySide>
      <SideBySide
        image={documentsGlossary}
        background={theme.palette.primary.background}
        imageSide="left"
        verticalPadding={1}
        mobileVerticalPadding={1}
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          paddingBottom={2}
        >
          Sustainability Glossary of Terms
        </Typography>
        <ActionButton
          onclick={() => window.open(sustainabilityGlossary, '_blank')}
          text="Download Glossary"
        />
      </SideBySide>
      {selectedSpeaker !== '' && (
        <SpeakerDialog
          selectedSpeaker={selectedSpeaker}
          setSelectedSpeaker={setSelectedSpeaker}
        />
      )}
      <Container
        disableGutters
        sx={{
          width: usePageWidth(),
          backgroundColor: theme.palette.secondary.background,
          px: usePadding(),
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 18,
        }}
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
        >
          Breathe Easy
        </Typography>
        <Typography variant="h4Light" gutterBottom>
          DPD held its first-ever virtual event on the topic of clean air in our
          cities.
        </Typography>
        <Typography variant="body1" color="#000" gutterBottom>
          Almost 500 people joined us from businesses, associated charities,
          government, science and the Green space to explore some of the steps
          being taken to reduce air pollution. A group of expert speakers each
          outlined the innovation methods their organisations are employing to
          track air quality and what must be done to improve it. Please watch
          the event or download the white paper.
        </Typography>
        <Grid container spacing={2}>
          {largeBreatheSpeakers.map(speaker => (
            <Grid item md={6} xs={12} key={speaker.name}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.background,
                  height: '100%',
                }}
              >
                <iframe
                  title={speaker.name}
                  src={speaker.link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="200px"
                  width="100%"
                />
                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="h5Light"
                    color={theme.palette.primary.core}
                    gutterBottom
                    onClick={() => {
                      setSelectedSpeaker(speaker);
                      tracker.logEvent(DOCUMENTS.CLICK_VIDEO_MODAL);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {speaker.name}
                  </Typography>
                  <Typography variant="body1" color="#000">
                    {speaker.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          {smallBreatheSpeakers.map(speaker => (
            <Grid item md={4} xs={12} key={speaker.name}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.background,
                  height: '100%',
                }}
              >
                <iframe
                  title={speaker.name}
                  src={speaker.link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height="200px"
                  width="100%"
                />
                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="h5Light"
                    color={theme.palette.primary.core}
                    gutterBottom
                    onClick={() => {
                      setSelectedSpeaker(speaker);
                      tracker.logEvent(DOCUMENTS.CLICK_VIDEO_MODAL);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {speaker.name}
                  </Typography>
                  <Typography variant="body1" color="#000">
                    {speaker.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Trackable>
  );
};

export default Documents;
