import { Container, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';

import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';

const PageHeader = ({ image, children }) => {
  return (
    <Container
      disableGutters
      sx={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minWidth: '100%',
        py: useMediaQuery(theme.breakpoints.down('md')) ? 6 : 38,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: usePageWidth(), px: usePadding() }}>{children}</Box>
    </Container>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node,
};
