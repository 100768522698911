import { Typography, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { VISION_THIRTY } from '../../../data/analytics';

import theme from '../../../theme';

import visionThirtyHeader from '../../../images/OurInnovation/visionThirtyHeader.webp';
import visionThirtyHouseBackground from '../../../images/OurInnovation/visionThirtyHouseBackground.png';
import townsCitiesBackground from '../../../images/OurInnovation/townsCitiesBackgrounds.webp';
import visionThirtyDPDVan from '../../../images/OurInnovation/visionThirtyDPDVan.png';
import visionThirtyDPDVanSmall from '../../../images/OurInnovation/visionThirtyDPDVanSmall.png';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import TextOnBackground from '../../../components/textOnBackground/TextOnBackground.jsx';

const VisionThirty = () => {
  return (
    <Trackable
      interfaceId={VISION_THIRTY.INTERFACE_ID}
      loadId={VISION_THIRTY.ON_LOAD}
    >
      <PageHeader image={visionThirtyHeader}>
        <Typography variant="h5" color="#FFF">
          Our Sustainable Innovation
        </Typography>
        <Typography variant="h1" color="#FFF">
          Vision 30
        </Typography>
      </PageHeader>
      <TextOnBackground
        background={visionThirtyHouseBackground}
        textSide="left"
        verticalPadding={20}
        mobileVerticalPadding={4}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          gutterBottom
          color={theme.palette.primary.core}
        >
          DPD's journey in sustainable delivery continues with our
          industry-leading Vision 30 strategy
        </Typography>
        <Typography variant="h4Light" gutterBottom color="#000">
          which will see DPD commit to all-electric delivery in 30 UK towns and
          cities, covering 25% of the UK population, by the end of 2024.
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.core,
            p: 3,
            borderRadius: 1,
          }}
        >
          <Typography variant="body1" color={theme.palette.primary.white}>
            This strategy will see the delivery of{' '}
          </Typography>
          <Typography variant="h2Light" color={theme.palette.primary.white}>
            100 million parcels{' '}
          </Typography>
          <Typography variant="body1" color={theme.palette.primary.white}>
            on our all-electric van fleet, compared with only 1.3 million in
            2019.
          </Typography>
        </Box>
      </TextOnBackground>
      <BoxOnBackground
        background={townsCitiesBackground}
        boxSide="right"
        verticalPadding={30}
        mobileBottomPadding={28}
      >
        <Typography variant="h2Light" gutterBottom color="#FFF">
          The 30 towns and cities are:
        </Typography>
        <Typography variant="body1" color="#FFF">
          Bath, Birkenhead, Birmingham, Bradford, Brighton, Bristol, Cambridge,
          Cardiff, Carlisle, Coventry, Edinburgh, Gateshead, Glasgow, Hull,
          Kettering, Leeds, Leicester, Liverpool, London, Manchester, Newcastle,
          Newcastle-Under-Lyme, Nottingham, Oxford, Portsmouth, Reading,
          Shipley, Southampton, Stoke, York
        </Typography>
      </BoxOnBackground>
      <SideBySide
        image={
          useMediaQuery(theme.breakpoints.down('md'))
            ? visionThirtyDPDVanSmall
            : visionThirtyDPDVan
        }
        imageSide="right"
        background={theme.palette.secondary.backgroundLavendar}
        verticalPadding={25}
      >
        <Typography
          variant="h2Light"
          gutterBottom
          color={theme.palette.primary.core}
        >
          These cities were chosen based on their population and volume of
          deliveries.
        </Typography>
        <Typography
          variant="h4Light"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 6 }
          }
        >
          The total number of deliveries in all these cities combined will
          result in an estimated CO2 emissions reduction of over 14,000 tonnes.
        </Typography>
      </SideBySide>
    </Trackable>
  );
};

export default VisionThirty;
