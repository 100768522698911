import { Typography, useMediaQuery, Grid } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { INSPIRE } from '../../../data/analytics';

import inspireHeader from '../../../images/Social/inspireHeader.webp';
import inspireProgramme from '../../../images/Social/inspireProgramme.png';
import inspireValuable500 from '../../../images/Social/inspireValuable500.png';
import inspireHeaderBackground from '../../../images/Social/inspireHeaderBackground.png';
import inspireTextImage from '../../../images/Social/inspireTextImage.png';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import ActionButton from '../../../components/actionButton/ActionButton.jsx';
import theme from '../../../theme.js';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';
import { inspireBooklet } from '../../../data';

const Inspire = () => {
  return (
    <Trackable interfaceId={INSPIRE.INTERFACE_ID} loadId={INSPIRE.ON_LOAD}>
      <PageHeader image={inspireHeader}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: usePadding(), width: usePageWidth() }}
          direction={
            useMediaQuery(theme.breakpoints.down('md'))
              ? 'column-reverse'
              : 'row'
          }
        >
          <Grid item md={6}>
            <Typography variant="h5" color="#FFF" gutterBottom>
              Social
            </Typography>
            <Typography variant="h1" color="#FFF">
              DPD's Inspire
            </Typography>
          </Grid>
        </Grid>
      </PageHeader>
      <SideBySide
        image={inspireProgramme}
        imageSide="left"
        background={theme.palette.secondary.background}
        verticalPadding={20}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h4Light' : 'h3Light'
          }
          color="#000"
          paddingBottom={2}
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 4 }}
        >
          DPD's inclusion strategy includes working with schools, colleges,
          charities and other organisations to offer employment opportunities to
          those who are disabled, particularly those with learning disabilities
          and special educational needs, through its DPD Inspire programme.
        </Typography>
        <Typography variant="h5Light" color="#000">
          This is achieved through work experience, supported internships and
          inclusive apprenticeships.
        </Typography>
      </SideBySide>
      <SideBySide
        image={inspireValuable500}
        imageSide="right"
        background={theme.palette.primary.background}
        verticalPadding={20}
        mobileDirection="column-reverse"
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h4Light' : 'h3Light'
          }
          color="#000"
          paddingBottom={2}
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 4 }}
        >
          DPD is a Valuable 500 company and a Disability Confident Employer and
          as such DPD is actively looking to create further opportunities
          ensuring every area of the business is included in this part of our
          inclusion strategy.
        </Typography>
        <Typography variant="h5Light" color="#000">
          The Valuable 500 is a global movement putting disability on the
          business agenda. It's calling for 500 companies to unlock the
          potential of the 1.3 billion people living with disabilities around
          the world.
        </Typography>
      </SideBySide>
      <SideBySide
        imageBackground={inspireHeaderBackground}
        imageSide="left"
        image={inspireTextImage}
        verticalPadding={25}
      >
        <Typography
          variant="h3Light"
          color="#FFF"
          paddingBottom={2}
          sx={useMediaQuery(theme.breakpoints.down('md')) && { pt: 2 }}
        >
          At DPD we want to create a happy, inclusive and high-performing
          culture where diversity is celebrated so that all our people can
          flourish and feel a sense of belonging.
        </Typography>
        <ActionButton
          text="Find Out More"
          onclick={() => window.open(inspireBooklet, '_blank')}
        />
      </SideBySide>
    </Trackable>
  );
};

export default Inspire;
