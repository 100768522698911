import { Box, InputLabel, InputBase } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';

const CustomTextField = ({
  noOptionSelected,
  label,
  required,
  value,
  setValue,
  id,
}) => {
  return (
    <Box sx={{ pt: 2 }}>
      <InputLabel
        sx={[
          { fontStyle: theme.typography.label },
          noOptionSelected
            ? { color: '#000', opacity: 0.3 }
            : { color: theme.palette.primary.main },
        ]}
      >
        {label}
      </InputLabel>
      <InputBase
        value={value}
        onChange={e => setValue(e.target.value)}
        required={required}
        disabled={noOptionSelected}
        id={id}
        fullWidth
        sx={[
          { border: 'none', boxShadow: 'none', borderRadius: 1 },
          noOptionSelected
            ? {
                backgroundColor: '#000',
                opacity: 0.1,
                border: 'none',
                boxShadow: 'none',
              }
            : {
                backgroundColor: theme.palette.primary.background,
                '& .MuiInputBase-input': {
                  backgroundColor:
                    value !== ''
                      ? theme.palette.primary.core
                      : theme.palette.primary.background,
                  color: '#FFF',
                  boxShadow: 'none',
                  pl: 2,
                },
                '& .Mui-focused': {
                  backgroundColor: theme.palette.primary.background,
                },
              },
        ]}
      />
    </Box>
  );
};

export default CustomTextField;

CustomTextField.propTypes = {
  noOptionSelected: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  id: PropTypes.string,
};
