import { Close } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Dialog,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { postContactUs } from '../../apis/green';
import {
  CONTACT_PAGE,
  CONTACT_TRACK,
  CONTACT_TRACK_LOCAL,
} from '../../data/links';

import theme from '../../theme';
import ActionButton from '../actionButton/ActionButton.jsx';
import CustomTextField from '../customTextField/CustomTextField.jsx';
import ExternalLink from '../externalLink/ExternalLink.jsx';

const StyledBackdrop = () => {
  return <Backdrop sx={{ opacity: 0 }} open={false} />;
};

const ContactUs = ({
  contactOpen,
  handleCloseContact,
  formSubmitted,
  setFormSubmitted,
  selectedSubject,
  setSelectedSubject,
  isParcelQuery,
  setIsParcelQuery,
  noOptionSelected,
  setNoOptionSelected,
  email,
  setEmail,
  subject,
  setSubject,
  message,
  setMessage,
  acceptContact,
  setAcceptContact,
  recaptcha,
  setRecaptcha,
}) => {
  const mediaQuerySmall = useMediaQuery(theme.breakpoints.down('sm'));

  const linkStyles = {
    color: theme.palette.primary.core,
    textDecorationColor: theme.palette.primary.core,
    '&:hover': {
      color: theme.palette.primary.core,
      textDecorationColor: theme.palette.primary.core,
    },
  };

  const subjects = [
    'Suggestions',
    'Information Requests',
    'Raise an environmental concern',
    'Parcel Query',
  ];

  const handleChangeSubject = event => {
    setSelectedSubject(event.target.value);
    if (event.target.value === 'Parcel Query') {
      setAcceptContact(false);
      setRecaptcha(
        process.env.REACT_APP_SENTRY_ENV !== 'LIVE'
          ? process.env.REACT_APP_RECAPTCHA_KEY_TESTING
          : ''
      );
    }
  };

  const checkParcelQuery = useCallback(() => {
    if (selectedSubject === 'Parcel Query') {
      setIsParcelQuery(true);
    } else {
      setIsParcelQuery(false);
    }
  }, [selectedSubject, setIsParcelQuery]);

  const checkIfSelected = useCallback(() => {
    if (selectedSubject !== '') {
      setNoOptionSelected(false);
    } else {
      setNoOptionSelected(true);
    }
  }, [selectedSubject, setNoOptionSelected]);

  const handleRecaptcha = value => {
    setRecaptcha(value);
  };

  useEffect(() => {
    checkParcelQuery();
    checkIfSelected();
  }, [selectedSubject, checkParcelQuery, isParcelQuery, checkIfSelected]);

  const submitForm = () => {
    const fields = {
      contactSubject: selectedSubject,
      email,
      subject,
      message,
      recaptcha,
    };
    postContactUs(fields);
  };

  return (
    <Dialog
      open={contactOpen}
      onClose={() => handleCloseContact()}
      components={{ Backdrop: StyledBackdrop }}
      PaperProps={{
        style: {
          borderRadius: 4,
          boxShadow: 'none',
          transition: 'none',
          backgroundColor: theme.palette.primary.background,
        },
      }}
    >
      <Box>
        <IconButton
          onClick={() => handleCloseContact()}
          sx={{
            color: theme.palette.primary.core,
            float: 'right',
            mr: 2,
            mt: 2,
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: 5,
          pt: 2,
          pb: 4,
          width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 580,
        }}
      >
        {!formSubmitted ? (
          <>
            <Typography
              variant="h2"
              color={theme.palette.primary.main}
              gutterBottom
            >
              Get in touch
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.primary.core}
              sx={{ pb: 2 }}
            >
              Please send us your enquiry below and one of our team will get
              back to you soon.
            </Typography>
            <InputLabel
              id="subjectLabel"
              sx={{
                color: theme.palette.primary.main,
                fontStyle: theme.typography.label,
              }}
            >
              Subject*
            </InputLabel>
            <Select
              sx={
                selectedSubject === ''
                  ? {
                      backgroundColor: theme.palette.primary.background,
                      color: theme.palette.primary.main,
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }
                  : {
                      backgroundColor: theme.palette.primary.core,
                      color: '#FFF',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }
              }
              MenuProps={{
                PaperProps: { sx: { mt: 1 } },
              }}
              labelId="subjectLabel"
              fullWidth
              size="small"
              value={selectedSubject}
              onChange={handleChangeSubject}
              id="subjectDropdown"
            >
              {subjects.map(subject => (
                <MenuItem
                  key={subject}
                  value={subject}
                  sx={
                    selectedSubject === subject
                      ? {
                          color: theme.palette.primary.core,
                          backgroundColor:
                            theme.palette.primary.background + ' !important',
                        }
                      : {
                          color: '#000',
                          opacity: 0.38,
                        }
                  }
                >
                  {subject}
                </MenuItem>
              ))}
            </Select>

            {isParcelQuery ? (
              <Box sx={{ pt: 2 }}>
                <Typography variant="body1" color="#000" gutterBottom>
                  All of your parcels latest updates and options can be viewed
                  via{' '}
                  <ExternalLink to={CONTACT_TRACK} styles={linkStyles}>
                    track.dpd.co.uk
                  </ExternalLink>{' '}
                  or{' '}
                  <ExternalLink to={CONTACT_TRACK_LOCAL} styles={linkStyles}>
                    track.dpdlocal.co.uk
                  </ExternalLink>
                </Typography>
                <Typography variant="body1" color="#000" gutterBottom>
                  Alternatively, you can contact our customer service team by
                  selecting the button below, where our team of advisors will be
                  happy to help
                </Typography>
                <Box sx={{ float: 'right' }}>
                  <ActionButton
                    text="Contact Customer Services"
                    href={CONTACT_PAGE}
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                <CustomTextField
                  label="Email*"
                  noOptionSelected={noOptionSelected}
                  required
                  value={email}
                  setValue={setEmail}
                  id="emailField"
                />
                <CustomTextField
                  label="Subject*"
                  noOptionSelected={noOptionSelected}
                  required
                  value={subject}
                  setValue={setSubject}
                  id="subjectField"
                />
                <CustomTextField
                  label="Message"
                  noOptionSelected={noOptionSelected}
                  required={false}
                  value={message}
                  setValue={setMessage}
                  id="messageField"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onChange={() => setAcceptContact(!acceptContact)}
                      required
                      id="acceptContact"
                    />
                  }
                  value={acceptContact}
                  label="By checking this box you confirm that you are happy for DPD to contact you"
                  disabled={noOptionSelected}
                  sx={{ color: '#000', opacity: 0.67, pt: 2 }}
                />
                <ReCAPTCHA
                  style={
                    mediaQuerySmall
                      ? { transformOrigin: 'left', transform: 'scale(0.70)' }
                      : { transformOrigin: 'left', transform: 'scale(1)' }
                  }
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleRecaptcha}
                />
                <Box sx={{ float: 'right', pt: 2 }}>
                  <Button
                    sx={{
                      color: '#FFF',
                      backgroundColor: theme.palette.primary.core,
                      borderRadius: 1,
                      py: '15px',
                      px: '30px',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.core,
                        opacity: 0.8,
                        color: '#FFF',
                      },
                      '&:disabled': {
                        color: '#FFF',
                        opacity: 0.5,
                      },
                    }}
                    onClick={() => {
                      submitForm();
                      setFormSubmitted(true);
                    }}
                    disabled={
                      selectedSubject === '' ||
                      email === '' ||
                      !email.includes('@') ||
                      subject === '' ||
                      recaptcha === '' ||
                      !acceptContact
                    }
                  >
                    <Typography variant="button">Submit</Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h2"
              color={theme.palette.primary.accentCore}
              gutterBottom
            >
              Thank You
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.primary.core}
              gutterBottom
            >
              Your message has been sent. Thank you for your enquiry
            </Typography>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default ContactUs;

ContactUs.propTypes = {
  contactOpen: PropTypes.bool,
  handleCloseContact: PropTypes.func,
  formSubmitted: PropTypes.bool,
  setFormSubmitted: PropTypes.func,
  selectedSubject: PropTypes.string,
  setSelectedSubject: PropTypes.func,
  isParcelQuery: PropTypes.bool,
  setIsParcelQuery: PropTypes.func,
  noOptionSelected: PropTypes.bool,
  setNoOptionSelected: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  subject: PropTypes.string,
  setSubject: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  acceptContact: PropTypes.bool,
  setAcceptContact: PropTypes.func,
  recaptcha: PropTypes.string,
  setRecaptcha: PropTypes.func,
};
