import { Container, Typography, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { NET_ZERO } from '../../../data/analytics';

import netZeroBackground from '../../../images/OurVision/netZeroBackground.webp';
import netZero from '../../../images/OurVision/netZero.png';
import pathway1 from '../../../images/OurVision/pathway1.png';
import pathway2 from '../../../images/OurVision/pathway2.png';
import pathway3 from '../../../images/OurVision/pathway3.png';
import pathway4 from '../../../images/OurVision/pathway4.png';
import { SBTiCertificate } from '../../../data';

import theme from '../../../theme';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const NetZero = () => {
  const pathwayActions = [
    {
      icon: pathway1,
      text: 'Electrifying the first and last mile delivery fleet',
    },
    {
      icon: pathway2,
      text:
        'Diversifying our fleet to include alternative options such as biogas, biofuel, electric and hydrogen powered trucks',
    },
    {
      icon: pathway3,
      text: 'Switching to 100% renewable energy, both gas and electricity',
    },
    {
      icon: pathway4,
      text: 'Electrifying our fleet of company cars',
    },
  ];

  return (
    <Trackable interfaceId={NET_ZERO.INTERFACE_ID} loadId={NET_ZERO.ON_LOAD}>
      <PageHeader image={netZeroBackground}>
        <Typography gutterBottom variant="h5" color="#FFF">
          Our Vision
        </Typography>
        <Typography gutterBottom variant="h1" color="#FFF">
          Net Zero
        </Typography>
      </PageHeader>
      <SideBySide
        image={netZero}
        imageSide="left"
        verticalPadding={20}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          paddingBottom={2}
          sx={{ color: theme.palette.primary.core }}
        >
          DPD as a part of GEOPOST will achieve its Science Based Target
          initiative (SBTi) validated net zero target set against a base year of
          2020
        </Typography>
        <Typography variant="body1" gutterBottom color="#000">
          The SBTi is a global body enabling businesses to set emission targets
          in line with the latest climate science and is widely recognised as
          leading the way for setting corporate net-zero commitments.
        </Typography>
        <Typography
          variant="body1"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 6 }
          }
        >
          SBTi is a collaboration between CDP (formerly the Carbon Disclosure
          Project), the United Nations Global Compact, the World Resources
          Institute (WRI) and the World Wide Fund for Nature (WWF).
        </Typography>
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.secondary.background,
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h2Light"
            gutterBottom
            color={theme.palette.primary.core}
          >
            DPD&apos;s accelerated decarbonisation pathway
          </Typography>
          <Typography variant="h5Light" gutterBottom color="#000">
            To achieve our net-zero commitment we will focus on the following
            key actions:
          </Typography>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            spacing={1}
          >
            {pathwayActions.map(pathway => (
              <Grid item key={pathway.text} md={6} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.core,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 250,
                    px: 4,
                    py: 8,
                  }}
                >
                  <Box component="img" src={pathway.icon} sx={{ pr: 2 }} />
                  <Typography variant="h5Light" color="#FFF">
                    {pathway.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" color="#000" sx={{ pt: 3 }}>
            SBTi provides DPD with a clearly defined and fully validated path to
            net zero, including scope 1, 2 and 3 (DPD aims to reduce its direct
            and indirect greenhouse gas emissions by 43% by 2030 and 90% by
            2040).
            <div>
              <a href={SBTiCertificate}>View our SBTi certificate here</a>
            </div>
          </Typography>
        </Box>
      </Container>
    </Trackable>
  );
};

export default NetZero;
