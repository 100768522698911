import { Container, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';
import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';

const BoxOnBackground = ({
  background,
  boxSide,
  children,
  verticalPadding,
  mobileBottomPadding,
  boxColor,
}) => {
  return (
    <Container
      disableGutters
      sx={[
        {
          minWidth: '100%',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: useMediaQuery(theme.breakpoints.down('md'))
            ? 3
            : verticalPadding || 10,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        },
        useMediaQuery(theme.breakpoints.down('md')) && {
          pb: mobileBottomPadding,
        },
      ]}
    >
      <Box sx={{ width: usePageWidth(), px: usePadding() }}>
        <Box
          sx={{
            borderRadius: 1,
            backgroundColor: boxColor || theme.palette.primary.core,
            maxWidth: useMediaQuery(theme.breakpoints.down('md'))
              ? '100%'
              : 500,
            px: 4,
            py: 6,
            float: boxSide,
          }}
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export default BoxOnBackground;

BoxOnBackground.propTypes = {
  background: PropTypes.string,
  boxSide: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  verticalPadding: PropTypes.number,
  mobileBottomPadding: PropTypes.number,
  boxColor: PropTypes.string,
};
